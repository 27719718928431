<template>
	<div class="dbs">
	<div class="site-section bg-light">
      <div class="container">
        <div class="row mb-5">
          <div class="col-12 text-center" style="margin-top: 60px;">
            <h2 class="section-title mb-3 text-normal-blue">Digital Business Solutions</h2>
          </div>
        </div>
        <div class="row mb-5">
          <div class="col-lg-5" data-aos="fade-right">
            <br><br>
            <img src="images/undraw_nakamoto_2iv6.svg" alt="Image" class="img-fluid">
          </div>
          <div class="col-lg-7 ml-auto pl-lg-7">
            <p class="mb-4 text-black">All organizations require innovative solutions to survive and grow; however only few are able to realize them.</p>
            <p class="mb-4 text-black"> The world is changing. Disruption is constant and low hanging fruit is gone. Winning in today’s market requires functioning as an established player and a startup, simultaneously. Most companies are unable to navigate these opposing tensions.</p>
 
            <p class="mb-4 text-black">We understand innovation.  Clikry Consulting team has studied strategic innovation historically, academically and most importantly, in the real world.   We have developed a highly differentiated and proven solutions approach to help our clients develop and implement breakthrough strategies to address their most critical business challenges.</p>

            <h2 class="text-normal-blue mb-4 h4 font-weight-bold">Digital Growth Accelerator</h2>

            <p class="mb-4 text-black">Most companies have exhausted every avenue of growth. We apply our cloud digital approach to strategic innovation, open source and partner products to identify innovative ways to find. </p>

            <h2 class="text-normal-blue mb-4 h4 font-weight-bold">Multicultural Segmentation</h2>

            <p class="mb-4 text-black">While most companies are targeting multicultural segments, most have been tactical and have only scratched the surface, at clikry we leverage our unique multicultural engagements insights and innovative approaches to help clients uncover incremental multicultural and cross border revenue.</p>
          </div>
        </div>
      </div>
    </div>

    <Footer/>
    </div>
</template>

<script>
// @ is an alias to /src
import Footer from "@/components/Footer.vue";

export default {
  name: "Digital Business Solution",
  components: {
    Footer
  }
};
</script>